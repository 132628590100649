import TopBase, { TopBaseProps } from '../lib/TopBase';
import iconFile from '../../assets/images/icon_paper.gif';

class PaperTop extends TopBase {

    constructor(props: TopBaseProps) {
        super(props);
        this.type = 'paper';
        this.label = 'Paper';
        this.icon = iconFile;
        // OPENNEURO:
        this.description = '[en]Paper[/en][ja]関連論文[/ja]';
    }
}

export default PaperTop;