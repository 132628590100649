import React from 'react';
import Functions from '../../../functions';
import { ItemPresentation } from '../../lib/ItemUtil';
import DetailBase from '../lib/DetailBase';
import ItemTypeField from '../lib/field';
import OpenNeuroAuthor from '../lib/field/OpenNeuroAuthor';
import OpenNeuroFreeKeyword from '../lib/field/OpenNeuroFreeKeyword';
import OpenNeuroRelatedTo from '../lib/field/OpenNeuroRelatedTo';
import PresentationUtil from './PresentationUtil';

class PresentationDetail extends DetailBase {

    getFields() {
        const { lang } = this.props;
        const item = this.props.item as ItemPresentation;
        return [
            { label: '[en]Item Type[/en][ja]アイテムタイプ[/ja]', value: item.item_type_display_name },
            { label: 'ID', value: item.doi },
            { label: '[en]Language[/en][ja]言語[/ja]', value: <ItemTypeField.Language lang={lang} itemLang={item.lang} /> },
            { label: '[en]Title[/en][ja]タイトル[/ja]', value: Functions.mlang(item.title, lang) },
            { label: '[ja]概要[/ja][en]Outline[/en]', value: <ItemTypeField.Description lang={lang} description={item.description} /> },
            { label: '[ja]プレビュー[/ja][en]Preview[/en]', value: <ItemTypeField.Preview lang={lang} file={item.file} /> },
            { label: '[ja]発表資料[/ja][en]Presentation File[/en]', value: <ItemTypeField.ItemFile lang={lang} file={item.file} type="presentation_file" rights={item.rights} useCc={item.use_cc} ccCommercialUse={item.cc_commercial_use} ccModification={item.cc_modification} downloadLimit={item.attachment_dl_limit} /> },
            { label: '[ja]日付[/ja][en]Date[/en]', value: <ItemTypeField.PublicationDate lang={lang} year={item.publication_year} month={item.publication_month} mday={item.publication_mday} /> },
            { label: '[ja]ファイル形式[/ja][en]File Format[/en]', value: <PresentationUtil.PresentationType lang={lang} type={item.presentation_type} /> },
            { label: '[ja]作成者[/ja][en]Creator[/en]', value: <OpenNeuroAuthor lang={lang} author={item.creator} /> },
            { label: 'Readme', value: <ItemTypeField.Readme lang={lang} readme={item.readme} /> },
            { label: 'Rights', value: <ItemTypeField.Rights lang={lang} rights={item.rights} useCc={item.use_cc} ccCommercialUse={item.cc_commercial_use} ccModification={item.cc_modification} /> },
            { label: '[ja]フリーキーワード[/ja][en]Free keyword[/en]', value: <OpenNeuroFreeKeyword lang={lang} keyword={item.keyword} /> },
            { label: '[ja]最終更新日[/ja][en]Last Update Date[/en]', value: <ItemTypeField.DateTime lang={lang} date={item.last_update_date} /> },
            { label: '[ja]作成日[/ja][en]Creation Date[/en]', value: <ItemTypeField.DateTime lang={lang} date={item.creation_date} /> },
            { label: '[ja]所有者[/ja][en]Contributor[/en]', value: <ItemTypeField.Contributer lang={lang} uname={item.uname} name={item.name} /> },
            { label: '[ja]査読者[/ja][en]Referee[/en]', value: Functions.mlang(item.referee, lang) },
            { label: '[ja]更新履歴[/ja][en]Change Log[/en]', value: <ItemTypeField.ChangeLog lang={lang} changelog={item.changelog} /> },
            { label: '[ja]関連アイテム[/ja][en]Related Item[/en]', value: <OpenNeuroRelatedTo lang={lang} relatedTo={item.related_to} /> },
        ];
    }
}

export default PresentationDetail;