import React from 'react';
import { Helmet } from 'react-helmet';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import Config, { MultiLang } from '../config';
import Functions from '../functions';

interface Props {
    lang: MultiLang;
}

const News: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const ctitle = Functions.mlang('[en]News[/en][ja]ニュース[/ja]', lang);
    const title = 'What\'s new';
    return (
        <div className="news">
            <Helmet>
                <title>{title} - {ctitle} - {Config.SITE_TITLE}</title>
            </Helmet>
            <h2>{ctitle}</h2>
            <NoticeSiteHasBeenArchived lang={lang} />
            <div className="itemHead">
                <span className="itemTitle">{title}</span>
            </div>
            <div className="itemInfo">
                <span className="itemPoster">Poster&nbsp;:&nbsp;<span className="username">nijcadmin</span></span>
                <span className="itemPostDate">&nbsp;on 2018-05-29 15:51:21</span>
            </div>
            <div className="itemBody">
                <div className="itemText">
                    <ul>
                        <li>We start accepting new datasets and contents from you. Just select "User Menu&nbsp;&raquo;&nbsp;Register" for a workflow to register your dataset.</li>
                        <li>INCF J-Node OpenNeuro Repository has been officially public at the begining of July, 2018.</li>
                        <li>Based on a policy of RIKEN, <u><b>OpenNeuro Repository will be closed soon within March, 2019</b></u>, although we had just opened this service.</li>
                    </ul>
                    <div className="notice">
                        <h4>IMPORTANT NOTICE:</h4>
                        <ul>
                            <li>OpenNeuro Repository has stopped its service based on the new policy of INCF J-node.</li>
                            <li>Please DO NOT reposit new contents in this database any more.</li>
                            <li>Please DO NOT try to create a new account.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default News;
