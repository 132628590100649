import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Functions from '../../../functions';
import iconFile from '../../assets/images/icon_data.gif';
import ItemUtil, { ItemData } from '../../lib/ItemUtil';
import ListBase, { ListBaseProps } from '../lib/ListBase';
import DataUtil from './DataUtil';

class DataList extends ListBase {

    constructor(props: ListBaseProps) {
        super(props);
        this.label = 'Data';
        this.icon = iconFile;
    }

    renderIcon() {
        const { lang } = this.props;
        const item = this.props.item as ItemData;
        const data = item.file.filter((value) => {
            return value.file_type_name === 'preview';
        });
        let imgSrc = this.icon;
        let imgAlt = this.label;
        if (data.length > 0) {
            const preview = data.pop();
            if ('undefined' !== typeof preview) {
                imgSrc = ItemUtil.getPreviewFileUrl(preview);
                if ('' !== preview.caption) {
                    imgAlt = Functions.mlang(preview.caption, lang);
                }
            }
        }
        return <img src={imgSrc} alt={imgAlt} />;
    }

    renderBody() {
        const { lang } = this.props;
        const item = this.props.item as ItemData;
        const authors = item.experimenter.map((author, i) => {
            return <Fragment key={i}>{i > 0 && ', '}{Functions.mlang(author, lang)}</Fragment>
        });
        return (
            <>
                <Link to={this.url}>{Functions.mlang(item.title, lang)}</Link><br />
                <DataUtil.DataType lang={lang} type={item.data_type} /><br />
                {authors}
            </>
        );
    }
}

export default DataList;