import React from 'react';
import { Route, Switch } from 'react-router-dom';
import XoopsPathRedirect from '../common/XoopsPathRedirect';
import Config, { MultiLang } from '../config';
import Database from '../database/Database';
import DatabaseXoopsPathRedirect from '../database/DatabaseXoopsPathRedirect';
import Pico from '../pico/Pico';
import PicoXoopsPathRedirect from '../pico/PicoXoopsPathRedirect';
import Categories from './Categories';
import Footer from './Footer';
import Header from './Header';
import News from './News';
import Overview from './Overview';
import Welcome from './Welcome';

interface Props {
    lang: MultiLang;
}

const Page: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return <div id="page">
        <Header lang={lang} />
        <div id="main">
            <div className="mainContent">
                <Switch>
                    <Route exact path="/" render={() => <Welcome lang={lang} />} />
                    <Route exact path="/categories" render={() => <Categories lang={lang} />} />
                    <Route exact path="/overview" render={() => <Overview lang={lang} />} />
                    <Route exact path="/news" render={() => <News lang={lang} />} />
                    <Route path="/database" render={() => <Database {...props} />} />
                    {Config.PICO_MODULES.map((name) =>
                        <Route key={name} path={'/' + name} render={() => <Pico lang={lang} name={name} />} />
                    )}
                    <Route path="/modules/xoonips" render={() => <DatabaseXoopsPathRedirect lang={lang} />} />
                    {Config.PICO_MODULES.map((name) =>
                        <Route key={name} path={'/modules/' + name} render={() => <PicoXoopsPathRedirect lang={lang} name={name} />} />
                    )}
                    <Route component={XoopsPathRedirect} />
                </Switch>
            </div>
        </div>
        <Footer lang={lang} />
    </div>;
}

export default Page;
