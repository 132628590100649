import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import './assets/pico_main.css';
import PicoUtils from './lib/PicoUtils';
import PicoCategory from './PicoCategory';
import PicoContent from './PicoContent';
import PicoIndex from './PicoIndex';
import PicoMenu from './PicoMenu';

interface Props {
    lang: MultiLang;
    name: string;
}

const Pico = (props: Props) => {
    const { lang, name } = props;
    return (
        <>
            <Switch>
                <Route strict exact path={'/' + name} render={() => <Redirect to={'/' + name + '/'} />} />
                <Route path={'/' + name + '/'} render={(props: RouteComponentProps<{ path: string }>) => {
                    const { location } = props;
                    const { pathname, search } = location;
                    const path = pathname.replace('/' + name + '/', '');
                    if (path === '' || path === 'index.php') {
                        const params = new URLSearchParams(search);
                        const paramCatId = params.get('cat_id');
                        if (paramCatId !== null && paramCatId.match(/^\d+$/)) {
                            const catId = parseInt(paramCatId, 10);
                            return <PicoCategory lang={lang} name={name} catId={catId} />;
                        }
                        const paramContentId = params.get('content_id');
                        if (paramContentId !== null && paramContentId.match(/^\d+$/)) {
                            const contentId = parseInt(paramContentId, 10);
                            return <PicoContent lang={lang} name={name} contentId={contentId} />;
                        }
                        const paramPage = params.get('page');
                        if (paramPage !== null && paramPage === 'menu') {
                            return <PicoMenu lang={lang} name={name} />;
                        }
                        return <PicoIndex lang={lang} name={name} />;
                    }
                    const category = PicoUtils.getCategoryByPath(name, path);
                    if (category !== null) {
                        return <PicoCategory lang={lang} name={name} catId={category.id} />;
                    }
                    const content = PicoUtils.getContentByPath(name, path);
                    if (content !== null) {
                        return <PicoContent lang={lang} name={name} contentId={content.id} />;
                    }
                    return <PageNotFound lang={lang} />;
                }} />
                <Route component={PageNotFound} />
            </Switch>
        </>
    );
}

export default Pico;
