import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import Config, { MultiLang } from '../config';
import Functions from '../functions';

interface Props {
    lang: MultiLang;
}


const Categories: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = Functions.mlang('[en]Search by Categories[/en][ja]カテゴリから検索[/ja]', lang);
    return (
        <div className="categories">
            <Helmet>
                <title>{title} - {Config.SITE_TITLE}</title>
            </Helmet>
            <h2>{title}</h2>
            <NoticeSiteHasBeenArchived lang={lang} />
            <div className="clearfix">
                <div className="container">
                    <div className="name">{Functions.mlang('[ja]動物種[/ja][en]Species[/en]', lang)}</div>
                    <div className="content">
                        <ul>
                            <li><Link to="/database/search/advanced?binder.species=_human_">{Functions.mlang('[ja]ヒト[/ja][en]Human[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.species=_nonhuman%20primate_">{Functions.mlang('[ja]非ヒト霊長目[/ja][en]Nonhuman Primate[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.species=_rodents_">{Functions.mlang('[ja]齧歯目[/ja][en]Rodents[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.species=_other%20vertebrates_">{Functions.mlang('[ja]上記以外の脊椎動物[/ja][en]Other Vertebrates[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.species=_arthropod_">{Functions.mlang('[ja]節足動物[/ja][en]Arthropod[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.species=_other%20invertebrates_">{Functions.mlang('[ja]節足動物以外の無脊椎動物[/ja][en]Other Invertebrates[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.species=_plant_">{Functions.mlang('[ja]植物[/ja][en]Plant[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.species=_others_">{Functions.mlang('[ja]その他[/ja][en]Others[/en]', lang)}</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="container">
                    <div className="name">{Functions.mlang('[ja]スケール[/ja][en]Scale[/en]', lang)}</div>
                    <div className="content"><ul>
                        <li><Link to="/database/search/advanced?binder.scale=molecular%20level">{Functions.mlang('[ja]分子レベル[/ja][en]Molecular level[/en]', lang)}</Link></li>
                        <li><Link to="/database/search/advanced?binder.scale=cellular%20level">{Functions.mlang('[ja]細胞レベル[/ja][en]Cellular level[/en]', lang)}</Link></li>
                        <li><Link to="/database/search/advanced?binder.scale=tissue%2Fnetwork%20level">{Functions.mlang('[ja]組織・ネットワークレベル[/ja][en]Tissue/Network level[/en]', lang)}</Link></li>
                        <li><Link to="/database/search/advanced?binder.scale=individual%20level">{Functions.mlang('[ja]個体レベル[/ja][en]Individual level[/en]', lang)}</Link></li>
                        <li><Link to="/database/search/advanced?binder.scale=population%20level">{Functions.mlang('[ja]個体集団レベル[/ja][en]Population level[/en]', lang)}</Link></li>
                        <li><Link to="/database/search/advanced?binder.scale=multiple%20levels">{Functions.mlang('[ja]マルチレベル[/ja][en]Multiple levels[/en]', lang)}</Link></li>
                        <li><Link to="/database/search/advanced?binder.scale=others">{Functions.mlang('[ja]その他[/ja][en]Others[/en]', lang)}</Link></li>
                    </ul>
                    </div>
                </div>
                <div className="container">
                    <div className="name">{Functions.mlang('[ja]メソッド[/ja][en]Method[/en]', lang)}</div>
                    <div className="content">
                        <ul>
                            <li><Link to="/database/search/advanced?binder.method=electrophysiology">{Functions.mlang('[ja]電気生理[/ja][en]Electrophysiology[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.method=brain%20imaging">{Functions.mlang('[ja]脳イメージング[/ja][en]Brain Imaging[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.method=mathematical%20modeling%2Fanalysis">{Functions.mlang('[ja]数理モデル・解析[/ja][en]Mathematical Modeling/Analysis[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.method=simulation">{Functions.mlang('[ja]シミュレーション[/ja][en]Simulation[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.method=molecular%20biology">{Functions.mlang('[ja]分子生物学[/ja][en]Molecular Biology[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.method=cell%20signaling">{Functions.mlang('[ja]細胞シグナル伝達[/ja][en]Cell Signaling[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.method=psychophysics">{Functions.mlang('[ja]心理物理学[/ja][en]Psychophysics[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.method=neurogenetics">{Functions.mlang('[ja]神経遺伝学[/ja][en]Neurogenetics[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.method=computational%20neuroscience">{Functions.mlang('[ja]計算論的神経科学[/ja][en]Computational Neuroscience[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.method=behavioral%20neuroscience">{Functions.mlang('[ja]行動学的神経科学[/ja][en]Behavioral Neuroscience[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.method=clinical%20neuroscience">{Functions.mlang('[ja]臨床神経科学[/ja][en]Clinical Neuroscience[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.method=integrative%20neuroscience">{Functions.mlang('[ja]統合神経科学[/ja][en]Integrative Neuroscience[/en]', lang)}</Link></li>
                            <li><Link to="/database/search/advanced?binder.method=brain%20machine%20interface">{Functions.mlang('[ja]ブレインマシンインタフェイス[/ja][en]Brain Machine Interface[/en]', lang)}</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="clearfix">
                <div className="container">
                    <div className="name">{Functions.mlang('[ja]タイトル[/ja][en]Title[/en]', lang)}</div>
                    <div className="content">
                        <ul>
                            <li><Link to="/database/list/8">A - E</Link></li>
                            <li><Link to="/database/list/9">F - J</Link></li>
                            <li><Link to="/database/list/10">K - N</Link></li>
                            <li><Link to="/database/list/11">O - S</Link></li>
                            <li><Link to="/database/list/12">T - X</Link></li>
                            <li><Link to="/database/list/13">Y - Z</Link></li>
                            <li><Link to="/database/list/14">Others</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="container reseach_areas">
                    <div className="name">{Functions.mlang('[ja]コンテンツ分野[/ja][en]Research Areas[/en]', lang)}</div>
                    <div className="content">
                        <ul>
                            <li><Link to="/database/search/advanced?binder.research_area=visiome">Visiome Platform</Link></li>
                            <li><Link to="/database/search/advanced?binder.research_area=bmi">Brain Machine Interface Platform</Link></li>
                            <li><Link to="/database/search/advanced?binder.research_area=invbrain">Invertebrate Brain Platform</Link></li>
                            <li><Link to="/database/search/advanced?binder.research_area=braintx">Brain Transcriptome Database</Link></li>
                            <li><Link to="/database/search/advanced?binder.research_area=cerebellum">Cerebellar Platform</Link></li>
                            <li><Link to="/database/search/advanced?binder.research_area=nimg">NeuroImaging Platform</Link></li>
                            <li><Link to="/database/search/advanced?binder.research_area=dynamicbrain">Dynamic Brain Platform</Link></li>
                            <li><Link to="/database/search/advanced?binder.research_area=cbsn">Comprehensive Brain Science Platform</Link></li>
                            <li><Link to="/database/search/advanced?binder.research_area=mouse-phenotype">Mouse Phenotype Database</Link></li>
                            <li><Link to="/database/search/advanced?binder.research_area=sim">Simulation Platform</Link></li>
                            <li><Link to="/database/search/advanced?binder.research_area=bsd">Brain Science Dictionary</Link></li>
                            <li><Link to="/database/search/advanced?binder.research_area=vibrism">ViBrism Database</Link></li>
                            <li><Link to="/database/search/advanced?binder.research_area=cbs-ni">CBS Neuroinformatics</Link></li>
                            <li><Link to="/database/search/advanced?binder.research_area=cns">Comparative Neuroscience Platform</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="jumpToTop">
                <a href="#main">Jump to the top</a>
            </div>
        </div>
    );
}

export default Categories;
