import React from 'react';
import { MultiLang } from '../config';

interface Props {
    lang: MultiLang;
}

const Footer: React.FC<Props> = (props: Props) => {
    return (
        <footer className="footer">
            <span className="copyright">Copyright (C) 2018 Neuroinformatics Unit, RIKEN Center for Brain Science</span>
        </footer>
    );
}

export default Footer;
