import React from 'react';
import { Link } from 'react-router-dom';
import Functions from '../../../functions';
import iconFile from '../../assets/images/icon_binder.gif';
import { ItemBinder } from '../../lib/ItemUtil';
import ListBase, { ListBaseProps } from '../lib/ListBase';

class BinderList extends ListBase {

    constructor(props: ListBaseProps) {
        super(props);
        this.label = 'Binder';
        this.icon = iconFile;
    }

    renderBody() {
        const { lang } = this.props;
        const item = this.props.item as ItemBinder;
        // OPENNEURO:
        return (
            <>
                <Link to={this.url}>{Functions.mlang(item.title, lang)}</Link>
            </>
        );
    }
}

export default BinderList;
