import React from 'react';
import { MultiLang } from '../../../../config';
import Functions from '../../../../functions';

interface Props {
    lang: MultiLang;
    author: string[];
}

const OpenNeuroAuthor = (props: Props) => {
    const { lang, author } = props;
    if (author.length === 0) {
        return null;
    }
    const label = author.map((value, idx) => <div key={idx}><span>{Functions.mlang(value, lang)}</span></div>);
    return <div className="openneuroAuthor">{label}</div>;
}

export default OpenNeuroAuthor;