import React from 'react';
import { MultiLang } from '../../../config';
import Functions from '../../../functions';
import { ItemBinder } from '../../lib/ItemUtil';
import DetailBase from '../lib/DetailBase';
import ItemTypeField from '../lib/field';
import OpenNeuroFreeKeyword from '../lib/field/OpenNeuroFreeKeyword';
import OpenNeuroRelatedTo from '../lib/field/OpenNeuroRelatedTo';

interface Props {
    lang: MultiLang;
    item: ItemBinder;
}

class BinderDetail extends DetailBase {

    getFields() {
        const { lang } = this.props;
        const item = this.props.item as ItemBinder;
        // OPENNEURO:
        const getResearchAreas = (value: string) => {
            const list: { [key: string]: string } = {
                visiome: "Visiome Platform",
                bmi: "Brain Machine Interface Platform",
                invbrain: "Invertebrate Brain Platform",
                braintx: "Brain Transcriptome Database",
                cerebellum: "Cerebellar Platform",
                nimg: "NeuroImaging Platform",
                dynamicbrain: "Dynamic Brain Platform",
                cbsn: "Comprehensive Brain Science Platform",
                "mouse-phenotype": "Mouse Phenotype Database",
                sim: "Simulation Platform",
                bsd: "Brain Science Dictionary",
                vibrism: "ViBrism Database",
                "cbs-ni": "CBS Neuroinformatics",
                cns: "Comparative Neuroscience Platform"
            };
            return list.hasOwnProperty(value) ? Functions.mlang(list[value], lang) : '';
        };
        const getSpecies = (value: string) => {
            const list: { [key: string]: string } = {
                "_human_": "[ja]ヒト[/ja][en]Human[/en]",
                "_nonhuman primate_": "[ja]非ヒト霊長目[/ja][en]Nonhuman Primate[/en]",
                "_rodents_": "[ja]齧歯目[/ja][en]Rodents[/en]",
                "_other vertebrates_": "[ja]上記以外の脊椎動物[/ja][en]Other Vertebrates[/en]",
                "_arthropod_": "[ja]節足動物[/ja][en]Arthropod[/en]",
                "_other invertebrates_": "[ja]節足動物以外の無脊椎動物[/ja][en]Other Invertebrates[/en]",
                "_plant_": "[ja]植物[/ja][en]Plant[/en]",
                "_others_": "[ja]その他[/ja][en]Others[/en]"
            };
            return list.hasOwnProperty(value) ? Functions.mlang(list[value], lang) : '';
        }
        const getScale = (value: string) => {
            const list: { [key: string]: string } = {
                "molecular level": "[ja]分子レベル[/ja][en]Molecular level[/en]",
                "cellular level": "[ja]細胞レベル[/ja][en]Cellular level[/en]",
                "tissue/network level": "[ja]組織・ネットワークレベル[/ja][en]Tissue/Network level[/en]",
                "individual level": "[ja]個体レベル[/ja][en]Individual level[/en]",
                "population level": "[ja]個体集団レベル[/ja][en]Population level[/en]",
                "multiple levels": "[ja]マルチレベル[/ja][en]Multiple levels[/en]",
                "others": "[ja]その他[/ja][en]Others[/en]"

            };
            return list.hasOwnProperty(value) ? Functions.mlang(list[value], lang) : '';
        }
        const getMethod = (value: string) => {
            const list: { [key: string]: string } = {
                "electrophysiology": "[ja]電気生理[/ja][en]Electrophysiology[/en]",
                "brain imaging": "[ja]脳イメージング[/ja][en]Brain Imaging[/en]",
                "mathematical modeling/analysis": "[ja]数理モデル・解析[/ja][en]Mathematical Modeling/Analysis[/en]",
                "simulation": "[ja]シミュレーション[/ja][en]Simulation[/en]",
                "molecular biology": "[ja]分子生物学[/ja][en]Molecular Biology[/en]",
                "cell signaling": "[ja]細胞シグナル伝達[/ja][en]Cell Signaling[/en]",
                "psychophysics": "[ja]心理物理学[/ja][en]Psychophysics[/en]",
                "neurogenetics": "[ja]神経遺伝学[/ja][en]Neurogenetics[/en]",
                "computational neuroscience": "[ja]計算論的神経科学[/ja][en]Computational Neuroscience[/en]",
                "behavioral neuroscience": "[ja]行動学的神経科学[/ja][en]Behavioral Neuroscience[/en]",
                "clinical neuroscience": "[ja]臨床神経科学[/ja][en]Clinical Neuroscience[/en]",
                "integrative neuroscience": "[ja]統合神経科学[/ja][en]Integrative Neuroscience[/en]",
                "brain machine interface": "[ja]ブレインマシンインタフェイス[/ja][en]Brain Machine Interface[/en]"
            };
            return list.hasOwnProperty(value) ? Functions.mlang(list[value], lang) : '';
        }
        return [
            { label: '[en]Item Type[/en][ja]アイテムタイプ[/ja]', value: item.item_type_display_name },
            { label: 'ID', value: item.doi },
            { label: '[en]Title[/en][ja]タイトル[/ja]', value: Functions.mlang(item.title, lang) },
            { label: '[ja]コンテンツ分野[/ja][en]Research Areas[/en]', value: getResearchAreas(item.research_area) },
            { label: '[ja]動物種[/ja][en]Species[/en]', value: getSpecies(item.species) },
            { label: '[ja]スケール[/ja][en]Scale[/en]', value: getScale(item.scale) },
            { label: '[ja]メソッド[/ja][en]Method[/en]', value: getMethod(item.method) },
            { label: '[ja]サマリー[/ja][en]Summary[/en]', value: <ItemTypeField.Description lang={lang} description={item.description} /> },
            { label: '[ja]フリーキーワード[/ja][en]Free keyword[/en]', value: <OpenNeuroFreeKeyword lang={lang} keyword={item.keyword} /> },
            { label: '[ja]同意[/ja][en]Agree[/en]', value: Functions.mlang(item.agree, lang) },
            { label: '[ja]最終更新日[/ja][en]Last Update Date[/en]', value: <ItemTypeField.DateTime lang={lang} date={item.last_update_date} /> },
            { label: '[ja]作成日[/ja][en]Creation Date[/en]', value: <ItemTypeField.DateTime lang={lang} date={item.creation_date} /> },
            { label: '[ja]所有者[/ja][en]Contributor[/en]', value: <ItemTypeField.Contributer lang={lang} uname={item.uname} name={item.name} /> },
            { label: '[ja]査読者[/ja][en]Referee[/en]', value: Functions.mlang(item.referee, lang) },
            { label: '[ja]更新履歴[/ja][en]Change Log[/en]', value: <ItemTypeField.ChangeLog lang={lang} changelog={item.changelog} /> },
            { label: '[ja]関連アイテム[/ja][en]Related Item[/en]', value: <OpenNeuroRelatedTo lang={lang} relatedTo={item.item_link} /> },
        ];
    }
}

export default BinderDetail;