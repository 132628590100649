import React from 'react';
import Functions from '../../../functions';
import { ItemPaper } from '../../lib/ItemUtil';
import DetailBase from '../lib/DetailBase';
import ItemTypeField from '../lib/field';
import OpenNeuroAuthor from '../lib/field/OpenNeuroAuthor';
import OpenNeuroFreeKeyword from '../lib/field/OpenNeuroFreeKeyword';
import OpenNeuroRelatedTo from '../lib/field/OpenNeuroRelatedTo';
import PaperUtil from './PaperUtil';

class PaperDetail extends DetailBase {

    getFields() {
        const { lang } = this.props;
        const item = this.props.item as ItemPaper;
        // OPENNEURO:
        return [
            { label: '[en]Item Type[/en][ja]アイテムタイプ[/ja]', value: item.item_type_display_name },
            { label: 'ID', value: item.doi },
            { label: '[en]Language[/en][ja]言語[/ja]', value: <ItemTypeField.Language lang={lang} itemLang={item.lang} /> },
            { label: 'PubMed ID', value: <PaperUtil.PubmedLink lang={lang} pubmedId={item.pubmed_id} /> },
            { label: '[en]Title[/en][ja]タイトル[/ja]', value: Functions.mlang(item.title, lang) },
            { label: '[ja]アブストラクト[/ja][en]Abstract[/en]', value: <ItemTypeField.Description lang={lang} description={item.abstract} /> },
            { label: '[ja]著者[/ja][en]Author[/en]', value: <OpenNeuroAuthor lang={lang} author={item.author} /> },
            { label: '[ja]ジャーナル名[/ja][en]Journal[/en]', value: Functions.mlang(item.journal, lang) },
            { label: '[ja]出版年[/ja][en]Publication Year[/en]', value: item.publication_year },
            { label: '[ja]巻[/ja][en]Volume[/en]', value: item.volume },
            { label: '[ja]号[/ja][en]Number[/en]', value: item.number },
            { label: '[ja]ページ[/ja][en]Page[/en]', value: item.page },
            { label: '[ja]フリーキーワード[/ja][en]Free keyword[/en]', value: <OpenNeuroFreeKeyword lang={lang} keyword={item.keyword} /> },
            { label: '[ja]最終更新日[/ja][en]Last Update Date[/en]', value: <ItemTypeField.DateTime lang={lang} date={item.last_update_date} /> },
            { label: '[ja]作成日[/ja][en]Creation Date[/en]', value: <ItemTypeField.DateTime lang={lang} date={item.creation_date} /> },
            { label: '[ja]所有者[/ja][en]Contributor[/en]', value: <ItemTypeField.Contributer lang={lang} uname={item.uname} name={item.name} /> },
            { label: '[ja]査読者[/ja][en]Referee[/en]', value: Functions.mlang(item.referee, lang) },
            { label: '[ja]更新履歴[/ja][en]Change Log[/en]', value: <ItemTypeField.ChangeLog lang={lang} changelog={item.changelog} /> },
            { label: '[ja]関連アイテム[/ja][en]Related Item[/en]', value: <OpenNeuroRelatedTo lang={lang} relatedTo={item.related_to} /> },
        ];
    }
}

export default PaperDetail;