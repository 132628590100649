import React from 'react';
import { MultiLang } from '../../../../config';
import Functions from '../../../../functions';

interface Props {
    lang: MultiLang;
    keyword: string[];
}

const OpenNeuroFreeKeyword = (props: Props) => {
    const { lang, keyword } = props;
    if (keyword.length === 0) {
        return null;
    }
    const label = keyword.map((value, idx) => <div key={idx}><span>{Functions.mlang(value, lang)}</span></div>);
    return <div className="openneuroFreeKeyword">{label}</div>;
}

export default OpenNeuroFreeKeyword;