import AsyncLock from 'async-lock';
import axios from 'axios';
import loki from 'lokijs';
import funcs from '../../functions';
import simpfLinksJson from '../assets/simpf-links.json';
import AdvancedSearchQuery from './AdvancedSearchQuery';

interface SimPFLink {
    id: number;
    url: string;
}

interface ItemSubType<T> {
    type: T;
    label: string;
}

export type ItemSubTypes<T> = readonly ItemSubType<T>[];

export type ItemBasicLang = 'eng' | 'jpn' | 'fra' | 'deu' | 'esl' | 'ita' | 'dut' | 'sve' | 'nor' | 'dan' | 'fin' | 'por' | 'chi' | 'kor';
export interface ItemBasicIndex {
    index_id: number;
    title: string;
}
export interface ItemBasicChangeLog {
    log_date: number;
    log: string;
}
export interface ItemBasicFile {
    file_id: number;
    original_file_name: string;
    mime_type: string;
    file_size: number;
    caption: string;
    timestamp: number;
    file_type_name: string;
    file_type_display_name: string;
}
export interface ItemCore {
    item_id: number;
    doi: string;
}
export interface ItemBasic extends ItemCore {
    uid: number;
    description: string;
    last_update_date: number;
    creation_date: number;
    publication_year: number;
    publication_month: number;
    publication_mday: number;
    lang: ItemBasicLang;
    title: string;
    item_type_display_name: string;
    item_type_name: string;
    uname: string;
    name: string;
    item_url: string;
    index: ItemBasicIndex[];
    changelog: ItemBasicChangeLog[];
    related_to: number[];
    keyword: string[];
    file: ItemBasicFile[];
}

export interface ItemBinder extends ItemBasic {
    extra: string;
    item_link: number[];
    // OPENNEURO:
    research_area: string;
    species: string;
    scale: string;
    method: string;
    agree: string;
    referee: string;
    guide_to_temporaryuser: string;
}

export interface ItemBook extends ItemBasic {
    classfication: string;
    editor: string;
    publisher: string;
    isbn: string;
    url: string;
    attachment_dl_limit: number;
    attachment_dl_notify: number;
    author: string[];
}

export type ItemConferenceSubType = 'powerpoint' | 'pdf' | 'illustrator' | 'other';
export const ItemConferenceSubTypes: ItemSubTypes<ItemConferenceSubType> = [
    { type: 'powerpoint', label: 'PowerPoint' },
    { type: 'pdf', label: 'PDF' },
    { type: 'illustrator', label: 'Illustrator' },
    { type: 'other', label: 'Other' },
];
export interface ItemConference extends ItemBasic {
    presentation_type: ItemConferenceSubType;
    conference_title: string;
    place: string;
    abstract: string;
    conference_from_year: number;
    conference_from_month: number;
    conference_from_mday: number;
    conference_to_year: number;
    conference_to_month: number;
    conference_to_mday: number;
    attachment_dl_limit: number;
    attachment_dl_notify: number;
    author: string[];
}

export type ItemDataSubType = 'excel' | 'movie' | 'text' | 'picture' | 'other';
export const ItemDataSubTypes: ItemSubTypes<ItemDataSubType> = [
    { type: 'excel', label: 'Excel' },
    { type: 'movie', label: 'Movie' },
    { type: 'text', label: 'Text' },
    { type: 'picture', label: 'Picture' },
    { type: 'other', label: 'Other' },
];
export interface ItemData extends ItemBasic {
    data_type: ItemDataSubType;
    rights: string;
    readme: string;
    use_cc: number;
    cc_commercial_use: number;
    cc_modification: number;
    attachment_dl_limit: number;
    attachment_dl_notify: number;
    experimenter: string[];
    // OPENNEURO:
    referee: string;
}

export type ItemFilesSubType = 'pdf' | 'doc' | 'xls' | 'ppt' | 'docx' | 'xlsx' | 'pptx' | 'zip' | 'lzh' | 'mov' // CBSN extended
export const ItemFilesSubTypes: ItemSubTypes<ItemFilesSubType> = [
    { type: 'pdf', label: 'pdf' },
    { type: 'doc', label: 'doc' },
    { type: 'xls', label: 'xls' },
    { type: 'ppt', label: 'ppt' },
    { type: 'docx', label: 'docx' },
    { type: 'xlsx', label: 'xlsx' },
    { type: 'pptx', label: 'pptx' },
    { type: 'zip', label: 'zip' },
    { type: 'lzh', label: 'lzh' },
    { type: 'mov', label: 'mov' },
];
export interface ItemFiles extends ItemBasic {
    data_file_name: string;
    data_file_mimetype: string;
    data_file_filetype: string;
}

export interface ItemMemo extends ItemBasic {
    item_link: string;
}

export type ItemModelSubType = 'matlab' | 'neuron' | 'original_program' | 'satellite' | 'genesis' | 'a_cell' | 'other';
export const ItemModelSubTypes: ItemSubTypes<ItemModelSubType> = [
    { type: 'matlab', label: 'Matlab' },
    { type: 'neuron', label: 'Neuron' },
    { type: 'original_program', label: 'Original Program' },
    { type: 'satellite', label: 'Satellite' },
    { type: 'genesis', label: 'Genesis' },
    { type: 'a_cell', label: 'A-Cell' },
    { type: 'other', label: 'Other' },
];
export interface ItemModel extends ItemBasic {
    model_type: ItemModelSubType;
    readme: string;
    rights: string;
    use_cc: number;
    cc_commercial_use: number;
    cc_modification: number;
    attachment_dl_limit: number;
    attachment_dl_notify: number;
    creator: string[];
}

export interface ItemPaper extends ItemBasic {
    journal: string;
    volume: number;
    number: number | null;
    page: string;
    abstract: string;
    pubmed_id: string;
    author: string[];
    // OPENNEURO:
    referee: string;
}

// OPENNEURO:
export type ItemPresentationSubType = 'powerpoint' | 'illustrator' | 'lotus' | 'justsystem' | 'html' | 'pdf' | 'other';
// export type ItemPresentationSubType = 'powerpoint' | 'lotus' | 'justsystem' | 'html' | 'pdf' | 'other';
export const ItemPresentationSubTypes: ItemSubTypes<ItemPresentationSubType> = [
    { type: 'powerpoint', label: 'PowerPoint' },
    { type: 'illustrator', label: 'Illustrator' }, // OPENNEURO:
    { type: 'lotus', label: 'Lotus' },
    { type: 'justsystem', label: 'JustSystem' },
    { type: 'html', label: 'HTML' },
    { type: 'pdf', label: 'PDF' },
    { type: 'other', label: 'Other' },
];
export interface ItemPresentation extends ItemBasic {
    presentation_type: ItemPresentationSubType;
    use_cc: number;
    cc_commercial_use: number;
    cc_modification: number;
    rights: string;
    readme: string;
    attachment_dl_limit: number;
    attachment_dl_notify: number;
    creator: string[];
    // OPENNEURO:
    referee: string;
}

export type ItemSimulatorSubType = 'matlab' | 'mathematica' | 'program' | 'other';
export const ItemSimulatorSubTypes: ItemSubTypes<ItemSimulatorSubType> = [
    { type: 'matlab', label: 'Matlab' },
    { type: 'mathematica', label: 'Mathematica' },
    { type: 'program', label: 'Program' },
    { type: 'other', label: 'Other' },
];
export interface ItemSimulator extends ItemBasic {
    simulator_type: ItemSimulatorSubType;
    readme: string;
    rights: string;
    use_cc: number;
    cc_commercial_use: number;
    cc_modification: number;
    attachment_dl_limit: number;
    attachment_dl_notify: number;
    developer: string[];
}

export type ItemStimulusSubType = 'picture' | 'movie' | 'program' | 'other';
export const ItemStimulusSubTypes: ItemSubTypes<ItemStimulusSubType> = [
    { type: 'picture', label: 'Picture' },
    { type: 'movie', label: 'Movie' },
    { type: 'program', label: 'Program' },
    { type: 'other', label: 'Other' },
];
export interface ItemStimulus extends ItemBasic {
    stimulus_type: ItemStimulusSubType;
    readme: string;
    rights: string;
    use_cc: number;
    cc_commercial_use: number;
    cc_modification: number;
    attachment_dl_limit: number;
    attachment_dl_notify: number;
    developer: string[];
}

export type ItemToolSubType = 'matlab' | 'mathematica' | 'program' | 'other';
export const ItemToolSubTypes: ItemSubTypes<ItemToolSubType> = [
    { type: 'matlab', label: 'Matlab' },
    { type: 'mathematica', label: 'Mathematica' },
    { type: 'program', label: 'Program' },
    { type: 'other', label: 'Other' },
];
export interface ItemTool extends ItemBasic {
    tool_type: ItemToolSubType;
    readme: string;
    rights: string;
    use_cc: number;
    cc_commercial_use: number;
    cc_modification: number;
    attachment_dl_limit: number;
    attachment_dl_notify: number;
    developer: string[];
}

export interface ItemUrl extends ItemBasic {
    url: string;
    url_count: number;
}

export type Item = ItemBinder | ItemBook | ItemConference | ItemData | ItemFiles | ItemMemo | ItemModel | ItemPaper | ItemPresentation | ItemSimulator | ItemStimulus | ItemTool | ItemUrl;

export type KeywordSearchType = 'all' | 'basic' | 'binder' | 'book' | 'conference' | 'data' | 'files' | 'memo' | 'model' | 'paper' | 'presentation' | 'simulator' | 'stimulus' | 'tool' | 'url';
export interface KeywordSearchQuery {
    type: KeywordSearchType;
    keyword: string;
}
const KEYWORD_SEARCH_TYPE_RANGE = ['all', 'basic', 'binder', 'book', 'conference', 'files', 'data', 'memo', 'model', 'paper', 'presentation', 'simulator', 'stimulus', 'tool', 'url'];

export type SortConditionLimit = 20 | 50 | 100;
export type SortConditionOrderBy = 'title' | 'doi' | 'last_update_date' | 'creation_date' | 'publication_date';
export enum SortConditionOrderDir { ASC, DESC }

export interface SortCondition {
    limit: SortConditionLimit;
    orderBy: SortConditionOrderBy;
    orderDir: SortConditionOrderDir;
    page: number;
}

class ItemSorter {

    public orderBy: SortConditionOrderBy;
    public orderDir: SortConditionOrderDir;

    constructor(condition: SortCondition) {
        this.orderBy = condition.orderBy;
        this.orderDir = condition.orderDir;
        this.sort = this.sort.bind(this);
    }

    sort(a: Item, b: Item) {
        let av: string = '';
        let bv: string = '';
        switch (this.orderBy) {
            case 'title':
                av = a.title.toLocaleUpperCase();
                bv = b.title.toLocaleUpperCase();
                break;
            case 'doi':
                av = a.doi.toLocaleUpperCase();
                bv = b.doi.toLocaleUpperCase();
                break;
            case 'last_update_date':
                av = String(a.last_update_date);
                bv = String(b.last_update_date);
                break;
            case 'creation_date':
                av = String(a.creation_date);
                bv = String(b.creation_date);
                break;
            case 'publication_date':
                av = String(a.publication_year * 10000 + a.publication_month * 100 + a.publication_mday);
                bv = String(b.publication_year * 10000 + b.publication_month * 100 + b.publication_mday);
                break;
            default:
                break;
        }
        if (this.orderDir === SortConditionOrderDir.ASC) {
            if (av > bv) return -1;
            else if (av < bv) return 1;
        } else {
            if (av > bv) return 1;
            else if (av < bv) return -1;
        }
        return 0;
    }
}

type GetResult = Item | null;
export interface GetCallbackFunc { (item: GetResult): void }
export interface SearchResult {
    total: number;
    data: Item[];
}
export interface SearchCallbackFunc { (results: SearchResult): void }
export interface SearchFunc { (condition: SortCondition, func: SearchCallbackFunc): void }

interface ItemLoadCallbackFunc { (items: Collection<Item>): void }

class ItemUtil {

    private database: loki;
    private items: Collection<Item>;
    private simpfLinks: Collection<SimPFLink>;
    private loading: boolean;
    private callbacks: ItemLoadCallbackFunc[];

    constructor() {
        this.database = new loki('database');
        this.items = this.database.addCollection('items');
        this.simpfLinks = this.database.addCollection('simpf-links');
        this.loading = true;
        this.callbacks = [];
        this.load();
    }

    load(): void {
        axios.get(process.env.PUBLIC_URL + '/database/items.json', { responseType: 'json' }).then((response) => {
            const itemsJson = response.data as Item[];
            itemsJson.forEach((json: Item) => {
                this.items.insert(json);
            });
            const lock = new AsyncLock();
            lock.acquire('items', () => {
                this.loading = false;
                this.callbacks.forEach((callback) => {
                    callback(this.items);
                });
                this.callbacks = [];
            });
        });
        const simpfLinks = simpfLinksJson as SimPFLink[];
        simpfLinks.forEach((simpfLink: SimPFLink) => {
            this.simpfLinks.insert(simpfLink);
        });
    }

    registerItemLoadCallback(func: ItemLoadCallbackFunc): void {
        const lock = new AsyncLock();
        lock.acquire('items', () => {
            if (this.loading) {
                this.callbacks.push(func);
            } else {
                func(this.items);
            }
        });
    }

    getUrl(item: ItemCore): string {
        if (item.doi !== '') {
            return '/database/item/id/' + funcs.escape(item.doi);
        }
        return '/database/item/' + String(item.item_id);
    }

    getFileUrl(file: ItemBasicFile): string {
        return process.env.PUBLIC_URL + '/database/file/' + String(file.file_id) + '/' + funcs.escape(file.original_file_name);
    }

    getPreviewFileUrl(file: ItemBasicFile): string {
        return process.env.PUBLIC_URL + '/database/file/' + String(file.file_id) + '.png';
    }

    getSearchByKeywordUrl(type: KeywordSearchType, keyword: string): string {
        const params = new URLSearchParams({ type, keyword });
        return '/database/search?' + params.toString();
    }

    getItemTypeSearchUrl(type: string): string {
        return '/database/search/itemtype/' + funcs.escape(type);
    }

    getSearchByAdvancedKeywordsUrl(query: AdvancedSearchQuery): string {
        const paramString = query.getQueryParams().toString();
        return '/database/search/advanced' + (paramString.length > 0 ? '?' + paramString : '');
    }

    getSearchKeywordByQuery(queryString: string): KeywordSearchQuery {
        const query = new URLSearchParams(queryString);
        const qtype = query.get('type');
        const type = qtype !== null && KEYWORD_SEARCH_TYPE_RANGE.includes(qtype) ? qtype as KeywordSearchType : 'all';
        const qkeyword = query.get('keyword');
        const keyword = qkeyword === null ? '' : qkeyword;
        return ({ type, keyword });
    }

    getAdvancedSearchQueryByQuery(queryString: string): AdvancedSearchQuery {
        const query: AdvancedSearchQuery = new AdvancedSearchQuery();
        query.setByQueryString(queryString);
        return query;
    }

    get(itemId: number, func: GetCallbackFunc): void {
        this.registerItemLoadCallback((items) => {
            const filter = {
                item_id: itemId
            }
            const item = items.findOne(filter);
            func(item);
        });
    }

    getByDoi(doi: string, func: GetCallbackFunc): void {
        this.registerItemLoadCallback((items) => {
            const filter = {
                doi: doi
            }
            const item = items.findOne(filter);
            func(item);
        });
    }

    getList(itemIds: number[], func: SearchCallbackFunc): void {
        this.registerItemLoadCallback((items) => {
            const filter = {
                item_id: {
                    '$in': itemIds,
                }
            }
            const sort = (a: Item, b: Item) => {
                const aIdx = itemIds.findIndex((itemId) => { return a.item_id === itemId; });
                const bIdx = itemIds.findIndex((itemId) => { return b.item_id === itemId; });
                if (aIdx > bIdx) {
                    return 1;
                } else if (aIdx < bIdx) {
                    return -1;
                }
                return 0;
            }
            const data = items.chain().find(filter).sort(sort).data();
            const res = {
                total: data.length,
                data: data,
            }
            func(res);
        });
    }

    getListByIndexId(indexId: number, condition: SortCondition, func: SearchCallbackFunc): void {
        this.registerItemLoadCallback((items) => {
            const filter: any = {
                'index.index_id': indexId
            };
            const offset = condition.limit * (condition.page - 1);
            const result = items.chain().find(filter);
            const itemSorter = new ItemSorter(condition);
            const ret = {
                total: result.count(),
                data: result.sort(itemSorter.sort).offset(offset).limit(condition.limit).data()
            };
            func(ret);
        });
    }

    getListByItemType(itemType: string, subItemType: string, condition: SortCondition, func: SearchCallbackFunc): void {
        this.registerItemLoadCallback((items) => {
            let filter: any = {
                item_type_name: 'xnp' + itemType,
            }
            if (subItemType !== '') {
                switch (itemType) {
                    case 'conference':
                        filter.presentation_type = subItemType;
                        break;
                    case 'data':
                        filter.data_type = subItemType;
                        break;
                    case 'files':
                        filter.data_file_filetype = subItemType;
                        break;
                    case 'model':
                        filter.model_type = subItemType;
                        break;
                    case 'presentation':
                        filter.presentation_type = subItemType;
                        break;
                    case 'simulator':
                        filter.simulator_type = subItemType;
                        break;
                    case 'stimulus':
                        filter.stimulus_type = subItemType;
                        break;
                    case 'tool':
                        filter.tool_type = subItemType;
                        break;
                    default:
                        break;
                }
            }
            const offset = condition.limit * (condition.page - 1);
            const result = items.chain().find(filter);
            const itemSorter = new ItemSorter(condition);
            const ret = {
                total: result.count(),
                data: result.sort(itemSorter.sort).offset(offset).limit(condition.limit).data()
            };
            func(ret);
        });
    }

    getListByKeyword(type: KeywordSearchType, keyword: string, condition: SortCondition, func: SearchCallbackFunc): void {
        this.registerItemLoadCallback((items) => {
            const regex = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const num = keyword.match(/^[0-9]+$/) ? parseInt(keyword, 10) : null;
            let filter: any = { '$or': [] };
            const appendToFilter = (type: string, strKeys: string[], numKeys: string[]) => {
                const basicStrKeys: string[] = ['title', 'keyword', 'doi', 'description', 'uname', 'name', 'index.title'];
                const basicNumKeys: string[] = [];
                let filterItemType: any = {
                    item_type_name: 'xnp' + type,
                    '$or': []
                };
                let sKeys: string[] = basicStrKeys.concat(strKeys);
                let nKeys: string[] = basicNumKeys.concat(numKeys);
                sKeys.forEach((key) => {
                    let criteria: any = {};
                    criteria[key] = { '$regex': [regex, 'i'] };
                    filterItemType['$or'].push(criteria);
                });
                if (num !== null) {
                    nKeys.forEach((key) => {
                        let criteria: any = {};
                        criteria[key] = { '$eq': num };
                        filterItemType['$or'].push(criteria);
                    });
                }
                filter['$or'].push(filterItemType);
            }
            if (type === 'basic') {
                filter['$or'].push({ 'title': { '$regex': [regex, 'i'] } });
                filter['$or'].push({ 'keyword': { '$regex': [regex, 'i'] } });
            } else {
                if (type === 'all' || type === 'binder') {
                    // OPENNEURO:
                    const strKeys: string[] = ['doi', 'title', 'keyword', 'description', 'research_area', 'species', 'scale', 'method'];
                    // const strKeys: string[] = ['doi', 'title', 'keyword', 'description'];
                    const numKeys: string[] = [];
                    appendToFilter('binder', strKeys, numKeys);
                }
                if (type === 'all' || type === 'book') {
                    const strKeys: string[] = ['doi', 'title', 'keyword', 'classification', 'editor', 'publisher', 'isbn', 'url', 'author', 'file.caption', 'file.original_file_name'];
                    const numKeys: string[] = [];
                    appendToFilter('book', strKeys, numKeys);
                }
                if (type === 'all' || type === 'conference') {
                    const strKeys: string[] = ['doi', 'title', 'conference_title', 'place', 'abstract', 'author', 'file.caption', 'file.original_file_name'];
                    const numKeys: string[] = ['conference_from_year', 'conference_from_month', 'conference_from_mday', 'conference_to_year', 'conference_to_month', 'conference_to_mday'];
                    appendToFilter('conference', strKeys, numKeys);
                }
                if (type === 'all' || type === 'data') {
                    const strKeys: string[] = ['doi', 'title', 'keyword', 'description', 'readme', 'rights', 'experimenter', 'data_type', 'file.caption', 'file.original_file_name'];
                    const numKeys: string[] = ['publication_year', 'publication_month', 'publication_mday'];
                    appendToFilter('data', strKeys, numKeys);
                }
                if (type === 'all' || type === 'files') {
                    const strKeys: string[] = ['doi', 'title', 'keyword', 'description', 'data_file_name', 'data_file_mimetype', 'data_file_filetype'];
                    const numKeys: string[] = [];
                    appendToFilter('files', strKeys, numKeys);
                }
                if (type === 'all' || type === 'memo') {
                    const strKeys: string[] = ['doi', 'title', 'keyword', 'description', 'item_link', 'file.original_file_name'];
                    const numKeys: string[] = [];
                    appendToFilter('memo', strKeys, numKeys);
                }
                if (type === 'all' || type === 'model') {
                    const strKeys: string[] = ['doi', 'title', 'keyword', 'description', 'readme', 'rights', 'creator', 'model_type', 'file.caption', 'file.original_file_name'];
                    const numKeys: string[] = [];
                    appendToFilter('model', strKeys, numKeys);
                }
                if (type === 'all' || type === 'paper') {
                    const strKeys: string[] = ['doi', 'title', 'keyword', 'journal', 'page', 'pubmed_id', 'author'];
                    const numKeys: string[] = ['publication_year', 'volume', 'number'];
                    appendToFilter('paper', strKeys, numKeys);
                }
                if (type === 'all' || type === 'presentation') {
                    const strKeys: string[] = ['doi', 'title', 'keyword', 'description', 'readme', 'rights', 'creator', 'presentation_type', 'file.caption', 'file.original_file_name'];
                    const numKeys: string[] = ['publication_year', 'publication_month', 'publication_mday'];
                    appendToFilter('presentation', strKeys, numKeys);
                }
                if (type === 'all' || type === 'simulator') {
                    const strKeys: string[] = ['doi', 'title', 'keyword', 'description', 'readme', 'rights', 'developer', 'simulator_type', 'file.caption', 'file.original_file_name'];
                    const numKeys: string[] = ['publication_year', 'publication_month', 'publication_mday'];
                    appendToFilter('simulator', strKeys, numKeys);
                }
                if (type === 'all' || type === 'stimulus') {
                    const strKeys: string[] = ['doi', 'title', 'keyword', 'description', 'readme', 'rights', 'developer', 'stimulus_type', 'file.caption', 'file.original_file_name'];
                    const numKeys: string[] = ['publication_year', 'publication_month', 'publication_mday'];
                    appendToFilter('stimulus', strKeys, numKeys);
                }
                if (type === 'all' || type === 'tool') {
                    const strKeys: string[] = ['doi', 'title', 'keyword', 'description', 'readme', 'rights', 'developer', 'tool_type', 'file.caption', 'file.original_file_name'];
                    const numKeys: string[] = [];
                    appendToFilter('tool', strKeys, numKeys);
                }
                if (type === 'all' || type === 'url') {
                    const strKeys: string[] = ['doi', 'title', 'keyword', 'url', 'file.original_file_name'];
                    const numKeys: string[] = [];
                    appendToFilter('url', strKeys, numKeys);
                }
                if (type !== 'all') {
                    filter['item_type_name'] = 'xnp' + type;
                }
            }
            const offset = condition.limit * (condition.page - 1);
            const result = items.chain().find(filter);
            const itemSorter = new ItemSorter(condition);
            const ret = {
                total: result.count(),
                data: result.sort(itemSorter.sort).offset(offset).limit(condition.limit).data()
            };
            func(ret);
        });
    }

    getListByAdvancedSearchQuery(query: AdvancedSearchQuery, condition: SortCondition, func: SearchCallbackFunc): void {
        this.registerItemLoadCallback((items) => {
            const filter: any = query.getSearchFilter();
            const offset = condition.limit * (condition.page - 1);
            const result = items.chain().find(filter);
            const itemSorter = new ItemSorter(condition);
            const ret = {
                total: result.count(),
                data: result.sort(itemSorter.sort).offset(offset).limit(condition.limit).data()
            };
            func(ret);
        });
    }

    getSimPFLinkUrl(itemId: number) {
        const simpfLink = this.simpfLinks.findOne({ id: itemId });
        if (simpfLink === null) {
            return '';
        }
        return simpfLink.url;
    }
}

export default new ItemUtil();