const SITE_TITLE = 'OpenNeuro Repository';
const SITE_SLOGAN = 'Welcome';
const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-122146455-1';
const XOONIPS_ITEMTYPES = ['binder', 'paper', 'data', 'tool', 'presentation', 'model'];
const PICO_MODULES = ['credits'];

export type MultiLang = 'en' | 'ja';

const Config = {
    SITE_TITLE,
    SITE_SLOGAN,
    GOOGLE_ANALYTICS_TRACKING_ID,
    XOONIPS_ITEMTYPES,
    PICO_MODULES,
}

export default Config;