import React from 'react';
import { Helmet } from 'react-helmet';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import Config, { MultiLang } from '../config';
import Functions from '../functions';
import imageOverview1 from './assets/images/overview1.png';
import imageOverview2 from './assets/images/overview2.png';

interface Props {
    lang: MultiLang;
}

const Overview: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = Functions.mlang('[en]Overview of OpenNeuro Repository[/en][ja]OpenNeuro Repository 概要[/ja]', lang);
    return (
        <div className="overview">
            <Helmet>
                <title>{title} - {Config.SITE_TITLE}</title>
            </Helmet>
            <h2>{title}</h2>
            <NoticeSiteHasBeenArchived lang={lang} />
            <div className="overview1">
                <img src={imageOverview1} alt="Overview" />
            </div>
            <p>
                The INCF J-Node <strong>OpenNeuro</strong> Repository archives research contents, such as primary data (the first-order information) used for peer-reviewed published papers and program codes for data analysis or computational models as supplemental materials of the papers.
                It covers a variety of contents at multiple scales of time and space, ranging from molecular level to organ and individual levels, across species. The registered contents will be reused by the communities of neuroscience to promote reproducibility, transparency and data accessibility of the research.
            </p>
            <div className="overview2">
                <img src={imageOverview2} alt="Sharing data for your published work with OpenNuero Repository!" />
            </div>
            <div className="jumpToTop">
                <a href="#main">Jump to the top</a>
            </div>
        </div>
    );
}

export default Overview;
