import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Loading from '../common/lib/Loading';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import XoopsCode from '../common/lib/XoopsCode';
import { MultiLang } from '../config';
import Functions from '../functions';
import PicoUtils, { PicoCategoryData, PicoPageData } from './lib/PicoUtils';

interface Props {
    lang: MultiLang;
    name: string;
    contentId: number;
}

interface State {
    loading: boolean;
    page: PicoPageData | null;
}

class PicoContent extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            page: null,
        };
        this.isActive = false;
    }

    componentDidMount() {
        const { name, contentId } = this.props;
        this.isActive = true;
        this.updatePage(name, contentId);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { name, contentId } = this.props;
        const prevName = prevProps.name;
        const prevContentId = prevProps.contentId;
        if (name !== prevName || contentId !== prevContentId) {
            this.setState({ loading: true, page: null });
            this.updatePage(name, contentId);
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    updatePage(name: string, contentId: number) {
        this.isActive = true;
        PicoUtils.getPage(name, contentId, (page: PicoPageData | null) => {
            if (this.isActive) {
                this.setState({ loading: false, page });
            }
        })
    }

    render() {
        const { lang, name, contentId } = this.props;
        const { loading, page } = this.state;
        if (loading) {
            return <Loading />;
        }
        if (page === null) {
            return <PageNotFound lang={lang} />;
        }
        const pico = PicoUtils.getModule(name);
        if (pico === null) {
            return <PageNotFound lang={lang} />;
        }
        const categories = PicoUtils.getContentCategery(name, contentId);
        return (
            <div className="pico_container">
                <Helmet>
                    <title>{Functions.mlang(page.title, lang)} - {Functions.mlang(pico.name, lang)} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h2>{Functions.mlang(pico.name, lang)}</h2>
                <NoticeSiteHasBeenArchived lang={lang} />
                {pico.show_breadcrumbs !== 0 && (
                    <div className="pico_breadcrumbs">
                        {categories.map((category: PicoCategoryData, idx: number) => {
                            const link = '/' + name + '/' + category.link;
                            return (
                                <Fragment key={category.id}>
                                    {idx > 0 && <>&nbsp;&gt;&nbsp;</>}
                                    <Link key={category.id} to={link}>{Functions.mlang(category.title, lang)}</Link>
                                </Fragment>
                            );
                        })}
                        &nbsp;&gt;&nbsp;{Functions.mlang(page.title, lang)}
                    </div>
                )}
                <div id="top_of_pico_body" className="pico_body">
                    <XoopsCode lang={lang} text={page.content} dohtml={true} />
                </div>
                <div className="bottom_of_content_body">
                    <HashLink to="#top_of_pico_body">{Functions.mlang('[en]Jump to the top[/en][ja]この記事の1行目に飛ぶ[/ja]', lang)}</HashLink>
                </div>
            </div>
        );
    }
}

export default PicoContent;
