import React, { ChangeEvent, Component, FormEvent } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import SelectLang from '../common/blocks/SelectLang';
import Config, { MultiLang } from '../config';
import Functions from '../functions';
import ItemUtil from '../database/lib/ItemUtil';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

interface State {
    keyword: string;
    showCreditsMenu: boolean;
}

class Header extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.state = { keyword: '', showCreditsMenu: false };
    }

    handleChangeKeyword(event: ChangeEvent<HTMLInputElement>) {
        const keyword = event.target.value;
        this.setState({ keyword });
    }

    handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const keyword = this.state.keyword.trim();
        if (keyword !== '') {
            const url = ItemUtil.getSearchByKeywordUrl('all', this.state.keyword);
            this.props.history.push(url);
        }
    }

    onMouseEnter() {
        this.setState({ showCreditsMenu: true });
    }

    onMouseLeave() {
        this.setState({ showCreditsMenu: false });
    }

    render() {
        const { lang } = this.props;
        return (
            <header className="header">
                <div className="clearfix">
                    <Link className="logo" to="/"><span className="hidden">{Config.SITE_TITLE}</span></Link>
                    <div className="utility">
                        <div><SelectLang lang={lang} /></div>
                        <div className="clearfix">
                            <a className="incf-japan-node" href="https://www.neuroinf.jp/" target="_blank" rel="noopener noreferrer"><span className="hidden">INCF Japan Node</span></a>
                            <div className="search-box">
                                <form onSubmit={this.handleSubmit}>
                                    <input type="text" value={this.state.keyword} onChange={this.handleChangeKeyword} placeholder="enter keywords here..." />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="mainmenu">
                    <li><Link to="/database/search/itemtype/binder">{Functions.mlang('[en]Contents[/en][ja]コンテンツ[/ja]', lang)}</Link></li>
                    <li><Link to="/categories">{Functions.mlang('[en]Categories[/en][ja]カテゴリから検索[/ja]', lang)}</Link></li>
                    <li><Link to="/overview">{Functions.mlang('[en]Overview[/en][ja]概要[/ja]', lang)}</Link></li>
                    <li><Link to="/news">{Functions.mlang('[en]News[/en][ja]ニュース[/ja]', lang)}</Link></li>
                    <li onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>{Functions.mlang('[en]Credits[/en][ja]サイト情報[/ja]', lang)}
                        {this.state.showCreditsMenu &&
                            <div className="submenu">
                                <Link className="item" to="/credits/1">Instruction for Use</Link>
                                <Link className="item" to="/credits/2">Copyrights</Link>
                                <Link className="item" to="/credits/3">Privacy Policy</Link>
                                <Link className="item" to="/credits/4">About Us</Link>
                            </div>
                        }
                    </li>
                </ul>
            </header>
        );

    }
}

export default withRouter(Header);
