import { ItemModelSubTypes } from '../../lib/ItemUtil';
import TopBase, { TopBaseProps } from '../lib/TopBase';
import iconFile from '../../assets/images/icon_model.gif';

class ModelTop extends TopBase {

    constructor(props: TopBaseProps) {
        super(props);
        this.type = 'model';
        this.label = 'Model';
        this.icon = iconFile;
        // OPENNEURO:
        this.description = '[en]Model Program/Script[/en][ja]モデル プログラム/スクリプト[/ja]';
        this.subTypes = ItemModelSubTypes;
    }
}

export default ModelTop;