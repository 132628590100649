import React from 'react';
import { Helmet } from 'react-helmet';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import Config, { MultiLang } from '../config';
import imageOpenAccess from './assets/images/open-access.png';

interface Props {
    lang: MultiLang;
}

const Welcome: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return (
        <div className="welcome">
            <Helmet>
                <title>{Config.SITE_TITLE} - {Config.SITE_SLOGAN}</title>
            </Helmet>
            <h1>Welcome</h1>
            <NoticeSiteHasBeenArchived lang={lang} />
            <div className="open-access">
                <img src={imageOpenAccess} alt="Open Access !" />
            </div>
            <p>Welcome to J-Node <strong>OpenNeuro</strong> repository!</p>
            <p>
                The INCF Japan Node platform <strong>OpenNeuro</strong> repository provides a reliable opportunity for researchers in the field of experimental and computational neuroscience to share supplemental materials to their peer-reviewed publications in academic journals and conference proceedings that are available in the public domain.
                It aims at playing a core role for promoting and accelerating neuroscience.
                Toward this end, we encourage databasing of
            </p>
            <ul>
                <li>complete sets of experimental image and/or time-series data,</li>
                <li>audiovisual materials,</li>
                <li>computational models,</li>
            </ul>
            <p>
                which have been described and/or analyzed in the publications, in particular if they are too large in size and/or too complicated to make them public as usual supplemental-material-mechanisms provided by academic journals, or if the authors wish to retain unique services of INCF Japan Node and  J-Node <strong>OpenNeuro</strong> repository such as real-time cloud computing services for the data registered in J-Node <strong>OpenNeuro</strong> repository.
                Each of those materials may be provided by standardized human/machine readable formats, such as model description languages, commonly utilized program source codes and/or executable/machine-readable binary codes. J-Node <strong>OpenNeuro</strong> repository will welcome those materials prior to publication to guarantee data accessibility, which will be accessible only for limited number of peer-reviewers during review process and then opened in the public domain immediately after publication.
            </p>
        </div>
    );
}

export default Welcome;
