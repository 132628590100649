import React, { Component } from 'react';
import ItemType from '../..';
import { MultiLang } from '../../../../config';
import ItemUtil from '../../../lib/ItemUtil';

interface Props {
    lang: MultiLang
    relatedTo: number[];
}

interface State {
    elements: JSX.Element[];
}

class OpenNeuroRelatedTo extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            elements: [],
        };
        this.isActive = false;
    }

    componentDidMount() {
        this.isActive = true;
        this.updateElements(this.props.relatedTo);
    }

    componentDidUpdate(prevProps: Props) {
        if (JSON.stringify(this.props.relatedTo) !== JSON.stringify(prevProps.relatedTo)) {
            this.updateElements(this.props.relatedTo);
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    updateElements(relatedTo: number[]) {
        const { lang } = this.props;
        if (relatedTo.length === 0) {
            this.setState({ elements: [] });
        } else {
            ItemUtil.getList(relatedTo, (results) => {
                const elements = results.data.map((item, idx) => {
                    const evenodd = 0 === idx % 2 ? 'even' : 'odd';
                    return <tr key={item.item_id}><td className={evenodd}><ItemType.List lang={lang} item={item} /></td></tr>;
                });
                if (this.isActive) {
                    this.setState({ elements });
                }
            });
        }
    }

    render() {
        if (this.state.elements.length === 0) {
            return null;
        }
        return (
            <table className="openneuroRelatedTo">
                <tbody>
                    {this.state.elements}
                </tbody>
            </table>
        );
    }
}

export default OpenNeuroRelatedTo;